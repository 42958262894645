import React from "react"

export const defaultNotificationContext = {
  notifications: [],
  dismissNotification: () => {},
  pushNotification: () => {},
  showNotification: () => {},
}

const NotificationContext = React.createContext(defaultNotificationContext)
export default NotificationContext

export const useNotificationContext = () =>
  React.useContext(NotificationContext)
