import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const TranslationContext = React.createContext({
  translationContext: { locale: {} },
  setTranslationContext: () => {},
})

export const TranslationContextProvider = ({
  translationContext,
  children,
}) => {
  const [state, setState] = useState(translationContext)

  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(state?.locale)
  }, [i18n, state?.locale])

  useEffect(() => {
    if (Object.keys(translationContext).length > 0) {
      setState(translationContext)
    }
  }, [translationContext])

  return (
    <TranslationContext.Provider
      value={{
        translationContext: state,
        setTranslationContext: setState,
      }}
    >
      {children}
    </TranslationContext.Provider>
  )
}

export const useTranslationContext = () => React.useContext(TranslationContext)
