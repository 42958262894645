import React from "react"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18next"

import { TranslationContextProvider } from "./src/context/TranslationContext"
import { ThemeProvider } from "./src/Theme/index"
import { InterfaceProvider } from "./src/context/InterfaceContext"
import { StoreProvider } from "./src/context/StoreContext"

const ONE_TRUST_SRC = process.env.GATSBY_ONE_TRUST || ""

export const onPreRenderHTML = ({
  getHeadComponents,
  replaceHeadComponents,
}) => {
  const headComponents = getHeadComponents()

  headComponents.push(
    React.createElement("script", {
      key: "onetrust",
      src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
      type: "text/javascript",
      charSet: "UTF-8",
      "data-domain-script": ONE_TRUST_SRC,
    })
  )
  headComponents.push(
    React.createElement(
      "script",
      {
        key: "onetrust-optanon",
        type: "text/javascript",
      },
      ["function OptanonWrapper() { }"]
    )
  )

  headComponents.push(
    React.createElement("meta", {
      key: "google-site-verification",
      name: "google-site-verification",
      content: "cxyXBILpSnkdYxZzyemX6Y8URyxoUSJh0f-RLVsS7nY",
    })
  )

  headComponents.push(
    React.createElement("meta", {
      key: "google-site-verification",
      name: "google-site-verification",
      content: "i1sNyJDGRQRsAH0P3zjuO6X4U47b_HQI53PFjHxQIzo",
    })
  )

  replaceHeadComponents(headComponents)
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <TranslationContextProvider translationContext={props.pageContext}>
      <StoreProvider>{element}</StoreProvider>
    </TranslationContextProvider>
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <InterfaceProvider>{element}</InterfaceProvider>
    </I18nextProvider>
  )
}
