import React from "react"
import styled from "@emotion/styled"

import { ImageCarousel } from "../../../new-ui/image"

const Container = styled.div`
  height: 650px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 40px;

  ${props => props.theme.breakpointsLegacy.tablet} {
    margin-left: 0;
    margin-right: 0;
  }
`

const GalleryWrap = ({ data }) => {
  return (
    <Container>
      <ImageCarousel
        blog={true}
        images={data.images.map(i => {
          return {
            i: i.id,
            gatsbyImageData: i.image.gatsbyImageData,
            linkUrl: i.linkUrl,
            title: i.title,
          }
        })}
      />
    </Container>
  )
}

export default GalleryWrap
