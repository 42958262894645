import { handleUrlPrefix, simpleWordTranslate } from "../translations-util"
import { ContentfulTypes } from "../types"

const handleReferenceLink = ({ reference, locale, linkUrl }) => {
  if (reference) {
    if (reference.internal.type === ContentfulTypes.ContentfulProduct) {
      if (reference.bedding_bundle) {
        const beddingBundle = reference.bedding_bundle?.[0]
        const collection = beddingBundle?.collection?.[0]
        const category = collection?.category?.[0]
        return handleUrlPrefix(
          locale,
          `${category.slug}/${collection.slug}/${reference.handle}`
        )
      }

      if (reference.collection?.[0].productPageType === "Standalone") {
        return handleUrlPrefix(
          locale,
          `${simpleWordTranslate(locale, "products")}/${reference.handle}`
        )
      }

      return handleUrlPrefix(
        locale,
        `${reference.collection?.[0].category?.[0].slug}/${reference.collection?.[0].slug}/${reference.handle}`
      )
    }
    if (reference.internal.type === ContentfulTypes.ContentfulPage) {
      return handleUrlPrefix(locale, reference.slug)
    }
  }
  if (linkUrl) {
    return handleUrlPrefix(locale, linkUrl)
  }

  return null
}

export default handleReferenceLink
