import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import styled from "@emotion/styled"
import {
  ConsentManagerBuilder,
  loadPreferences,
  doNotTrack,
} from "@segment/consent-manager"
import inEU from "@segment/in-eu"
import { Flex } from "theme-ui"

import Spacing from "../ui/Spacing"
import { useTranslation } from "react-i18next"
import Accordion from "../new-ui/accordion"
import { Cookie } from "../new-ui/cookies"
import useDisableBackgroundScroll from "../../hooks/useDisableBackgroundScroll"
import Button from "../new-ui/button"
import Toggle from "../new-ui/toggle/toggle"

const SEGMENT_WRITE_KEY = process.env.GATSBY_SEGMENT_WRITE_KEY || ""

export const MARKETING_AND_ANALYTICS_CATEGORIES = [
  "A/B Testing",
  "Analytics",
  "Attribution",
  "Email",
  "Enrichment",
  "Heatmaps & Recordings",
  "Raw Data",
  "Realtime Dashboards",
  "Referrals",
  "Surveys",
  "Video",
]

export const ADVERTISING_CATEGORIES = ["Advertising", "Tag Managers"]

export const FUNCTIONAL_CATEGORIES = [
  "CRM",
  "Customer Success",
  "Deep Linking",
  "Helpdesk",
  "Livechat",
  "Performance Monitoring",
  "Personalization",
  "SMS & Push Notifications",
  "Security & Fraud",
]

const Row = styled.div`
  ${Spacing}
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent || "space-between"};

  &.switches {
    ${(props) => props.theme.breakpointsLegacy.tablet} {
      width: 65%;
    }
  }

  &.but-container {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: row;

    .but-acc,
    .but-save {
      height: 40px;
      flex: 1;
      font-size: 14px;
      text-align: center;
      font-weight: bold;
      padding: 8px;
    }

    .but-save {
      border: ${(props) => props.theme.borders.muted};
      margin-right: 4px;
    }

    .but-acc {
      margin-left: 4px;
    }

    ${(props) => props.theme.breakpointsLegacy.tablet} {
      .but-acc,
      .but-save {
        height: 40px;
        width: 140px;
      }

      .but-acc {
        margin-bottom: 0;
      }
    }
  }
`

const Text = styled.div`
  margin-bottom: 20px;

  &.show-details {
    text-decoration: underline;
    cursor: pointer;
  }
`

const Modal = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    justify-content: flex-end;
    align-items: flex-end;
    padding: 16px;
  }
`

const ModalContent = styled.div`
  padding: 20px;
  width: 90%;
  max-height: 90vh;
  overflow-y: scroll;
  background-color: white;
  max-width: 600px;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding: 40px;
    width: 60%;
    filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.08));
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    padding: 50px;
  }
`

const Content = styled.div`
  max-width: 100%;
  font-size: 13px;

  h2 {
    font-size: 19px;
    font-weight: normal;
  }
`

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SwitchLabel = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
`

const CookieListContainer = styled(Flex)`
  flex-direction: column;
`
const CookieList = styled(Flex)`
  flex-direction: column;
  margin: 16px 0;
  max-height: 300px;
  overflow: scroll;
`

const CookieConsentBanner = () => {
  const { t } = useTranslation()
  const [showDialog, toggleDialog] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [cookieData, setCookieData] = useState()
  const [, setScrollable] = useDisableBackgroundScroll()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let intervalVar
    // OneTrust comes from the script in html.js
    // eslint-disable-next-line no-undef
    if (loading) {
      intervalVar = setInterval(() => {
        if (window.OneTrust) {
          // eslint-disable-next-line no-undef
          const data = window.OneTrust.GetDomainData()

          setCookieData(data)
          setLoading(false)
        }
      }, [2000])
    }

    return () => clearInterval(intervalVar)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <ConsentManagerBuilder
      writeKey={SEGMENT_WRITE_KEY}
      shouldRequireConsent={() => {
        const saved = loadPreferences()
        const doNot = doNotTrack()
        if (doNot !== null) {
          return isEmpty(saved)
        }

        return isEmpty(saved) ? inEU() : false
      }}
      defaultDestinationBehavior={"imply"}
      initialPreferences={{ analytics: false, advertisements: false }}
      mapCustomPreferences={(destinations, preferences) => {
        const prefs = destinations.reduce((acc, next) => {
          if (ADVERTISING_CATEGORIES.includes(next.category)) {
            acc[next.id] = !!preferences.advertisements
          } else {
            // Fallback to analytics
            acc[next.id] = !!preferences.analytics
          }
          return acc
        }, {})
        return {
          destinationPreferences: prefs,
        }
      }}
    >
      {({ isConsentRequired, preferences, setPreferences, saveConsent }) => {
        if (!isConsentRequired || !showDialog) {
          return null
        }
        if (isConsentRequired) {
          setScrollable(false)
        }
        return (
          <Modal show={isConsentRequired}>
            <ModalContent>
              <Content>
                <h2>{t("we_look_after_your_data")}</h2>
                <Text>{t("cookie_details_1")}</Text>
                <Text>{t("cookie_details_2")}</Text>
                <Row>
                  <Text
                    className="show-details"
                    onClick={() => setShowDetails((showing) => !showing)}
                  >
                    {showDetails ? t("hide_details") : t("show_details")}
                  </Text>
                </Row>
                {showDetails && (
                  <CookieListContainer>
                    <Accordion
                      items={cookieData?.Groups.reduce((acc, current) => {
                        if (current.Cookies?.length === 0) return acc
                        const item = {
                          id: current.GroupName,
                          name: current.GroupName,
                          contentRender: function createCookieList() {
                            return (
                              <CookieList>
                                <Text>{current.GroupDescription}</Text>
                                {current?.Cookies.map((cookie, index) => (
                                  <Cookie
                                    key={`${cookie.Name}-index-${index}`}
                                    name={cookie.Name}
                                    description={cookie.description}
                                    duration={cookie.Length}
                                  />
                                ))}
                              </CookieList>
                            )
                          },
                        }
                        acc.push(item)

                        return acc
                      }, [])}
                    />
                  </CookieListContainer>
                )}
                <Row className="but-container" justifyContent="flex-start">
                  <Button
                    className="but-save"
                    variant="transparent"
                    outline
                    secondary
                    onClick={() => {
                      setScrollable(true)
                      toggleDialog(false)
                      setPreferences({
                        advertisements: preferences.advertisements || false,
                        analytics: preferences.analytics || false,
                      })
                      saveConsent()
                    }}
                  >
                    {t("save_preferences")}
                  </Button>
                  <Button
                    className="but-acc"
                    variant="primary"
                    margin="0 0 0 20px"
                    onClick={() => {
                      setScrollable(true)
                      toggleDialog(false)
                      setPreferences({
                        advertisements: true,
                        analytics: true,
                      })
                      saveConsent()
                    }}
                  >
                    {t("accept")}
                  </Button>
                </Row>

                <Row justifyContent="space-between" className="switches">
                  <SwitchContainer>
                    <SwitchLabel>{t("necessary")}</SwitchLabel>
                    <Toggle alwaysOn={true} isOn={true} />
                  </SwitchContainer>
                  <SwitchContainer>
                    <SwitchLabel>{t("statistical")}</SwitchLabel>
                    <Toggle
                      isOn={!!preferences.analytics}
                      onClick={() => {
                        setPreferences({
                          analytics: !preferences.analytics,
                        })
                      }}
                    />
                  </SwitchContainer>
                  <SwitchContainer>
                    <SwitchLabel>{t("marketing")}</SwitchLabel>
                    <Toggle
                      isOn={!!preferences.advertisements}
                      onClick={() => {
                        setPreferences({
                          advertisements: !preferences.advertisements,
                        })
                      }}
                    />
                  </SwitchContainer>
                  <SwitchContainer>
                    <SwitchLabel>{t("functional")}</SwitchLabel>
                    <Toggle
                      isOn={!!preferences.functional}
                      onClick={() =>
                        setPreferences({ functional: !preferences.functional })
                      }
                    />
                  </SwitchContainer>
                </Row>
              </Content>
            </ModalContent>
          </Modal>
        )
      }}
    </ConsentManagerBuilder>
  )
}

export default CookieConsentBanner
