export const handleUrlPrefix = (language, url) => {
  if (!language || url === undefined || url === null) return
  const [lang] = language.split("-")
  if (url.charAt(0) === "/") {
    url = url.slice(1)
  }

  switch (lang) {
    case "en":
      return `/${url}`
    case "de":
      return `/${lang}/${url}`
    default:
      return `/${url}`
  }
}

export const simpleWordTranslate = (language, word) => {
  const [lang] = language?.split("-") || "en"

  switch (word) {
    case "products":
      switch (lang) {
        case "en":
          return "products"
        case "de":
          return "produkts"
      }
      break

    case "returns":
      switch (lang) {
        case "en":
          return "returns"
        case "de":
          return "retouren"
      }
      break

    case "summer-essentials":
      switch (lang) {
        case "en":
          return "summer-essentials"
        case "de":
          return "sommer-essentials"
      }
      break

    case "all-products":
      switch (lang) {
        case "en":
          return "l/all-products"
        case "de":
          return "l/alle-produkte"
      }
      break

    case "shared-wishlist": {
      switch (lang) {
        case "en":
          return "shared-wishlist"
        case "de":
          return "shared-wishlist"
      }
      break
    }

    default:
      return word
  }
}
