import React from "react"
import styled from "@emotion/styled"
import { Flex, Text } from "theme-ui"
import { useTranslation } from "react-i18next"

const CookieContainer = styled(Flex)`
  flex-direction: column;
  border-bottom: ${(props) => props.theme.borders.dark};
  padding: 8px;
  margin: 16px 0;
`

export const Cookie = ({ id, name, description }) => {
  const { t } = useTranslation()
  return (
    <CookieContainer key={id}>
      {name && (
        <Text mb={1}>
          <strong>{t("name")}:</strong> {name}
        </Text>
      )}
      {description && (
        <Text mb={1}>
          <strong>{t("description")}:</strong> {description}
        </Text>
      )}
    </CookieContainer>
  )
}
