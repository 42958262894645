import styled from "@emotion/styled"
import PropTypes from "prop-types"
import React from "react"
import { Button as RebassButton } from "theme-ui"

const StyledButton = styled(RebassButton)`
  ${(props) =>
    (props.disabled || props.isDisabled) &&
    `
      cursor: default;
      background-color: ${props.theme.colors.mutedGrey};
      
      :hover {
        background-color: ${props.theme.colors.mutedGrey};
      }
    `}
`

const Button = ({
  children,
  variant = "primary",
  onClick,
  disabled,
  icon,
  className,
  sx,
  ...rest
}) => {
  return (
    <StyledButton
      onClick={onClick}
      variant={`buttons.${variant}`}
      disabled={disabled}
      className={className}
      sx={sx}
      {...rest}
    >
      {children}
      {icon && icon}
    </StyledButton>
  )
}

export default Button

/*
  This button doesn't follow the usual disabled state, 
  but instead fires a specific disabledOnClick event
*/
export const ButtonDisabledOnClick = ({
  variant = "primary",
  children,
  onClick,
  isDisabled,
  disabledOnClick,
  icon,
  className,
  sx,
  ...rest
}) => {
  const handleClick = () => {
    if (isDisabled) {
      disabledOnClick()
    } else {
      onClick()
    }
  }

  return (
    <StyledButton
      onClick={handleClick}
      variant={`buttons.${variant}`}
      sx={sx}
      isDisabled={isDisabled}
      className={className}
      {...rest}
    >
      {children}
      {icon && icon}
    </StyledButton>
  )
}

Button.propTypes = {
  /**
    The variant indicated the type of button rendered
    */
  variant: PropTypes.string,
}
