export const colors = {
  black: "black",
  mobilePayBlack: "#3d3246",
  blackTransparent: "#0000001a",
  drawerBackground: "#00000035",
  darkest: "#101010",
  grey: "#E9E9E9",
  careGrey: "#E2E2DF",
  borderGrey: "#C4C4C4",
  dark: "#454545",
  dark_5: "#4545450D",
  dark_10: "#4545451A",
  dark_30: "#4545454D",
  dark_50: "#45454580",
  dark_80: "#454545B3",
  medium: "#707070",
  green: "#5FC07A",
  ctaGreen: "#72B866",
  brownGrey: "#9f9f9f",
  brownGrey_20: "#9f9f9f33",
  darkGrey: "#EEEDEC",
  mutedGrey: "#9E9D9D",
  highlightedGrey: "#696868",
  lightGrey: "#707070",
  light: "#dedede",
  almostWhite: "#f7f6f5",
  lightest: "#ffffff",
  danger: "#EB0000",
  red: "#CE4646",
  text: "#181818",
  textHover: "#485460",
  textMuted: "#6c757d",
  white: "white",
  accent: "#CE9756",
}
