import { borders } from "./borders"
import { colors } from "./colors"

const base = {
  borderRadius: 0,
  fontSize: "15px",
  border: 0,
  outline: 0,
  cursor: "pointer",
}

export const buttons = {
  primary: {
    ...base,
    color: colors.almostWhite,
    backgroundColor: colors.dark,
    ":hover": {
      backgroundColor: colors.highlightedGrey,
    },
    ":disabled": {
      backgroundColor: colors.mutedGrey,
    },
    ":active": {
      backgroundColor: colors.dark,
    },
  },
  secondary: {
    ...base,
    color: colors.almostWhite,
    backgroundColor: colors.ctaGreen,
  },
  danger: {
    ...base,
    color: colors.almostWhite,
    backgroundColor: colors.danger,
  },
  darkest: {
    ...base,
    color: colors.almostWhite,
    backgroundColor: colors.darkest,
  },
  light: {
    ...base,
    color: colors.dark,
    backgroundColor: colors.white,
    border: borders.dark,
  },
  base: {
    ...base,
    color: colors.dark,
    backgroundColor: colors.almostWhite,
    border: borders.dark,
  },
  transparent: {
    ...base,
    color: colors.dark,
    backgroundColor: "transparent",
    border: borders.dark,
  },
}
