const ContentfulProduct = "ContentfulProduct"
const ContentfulProductList = "ContentfulProductList"
const ContentfulBeddingBundle = "ContentfulBeddingBundle"
const ContentfulCollection = "ContentfulCollection"
const ContentfulCategory = "ContentfulCategory"
const ContentfulHero = "ContentfulHero"
const ContentfulAccordion = "ContentfulAccordion"
const ContentfulRichText = "ContentfulRichText"
const ContentfulGallery = "ContentfulGallery"
const ContentfulBlogContent = "ContentfulBlogContent"
const ContentfulTileSection = "ContentfulTileSection"
const ContentfulTile = "ContentfulTile"
const ContentfulNavigationItem = "ContentfulNavigationItem"
const ContentfulPage = "ContentfulPage"
const ContentfulSlider = "ContentfulSlider"
const ContentfulGlobalSizeGuide = "ContentfulGlobalSizeGuide"
const ContentfulTabs = "ContentfulTabs"
const ContentfulButton = "ContentfulButton"
const ContentfulImageLink = "ContentfulImageLink"
const ContentfulAssetWrapper = "ContentfulAssetWrapper"
const ContentfulCareModule = "ContentfulCareModule"
const ContentfulCarousel = "ContentfulCarousel"

export const ContentfulTypes = {
  ContentfulProduct: ContentfulProduct,
  ContentfulProductList: ContentfulProductList,
  ContentfulBeddingBundle: ContentfulBeddingBundle,
  ContentfulCollection: ContentfulCollection,
  ContentfulCategory: ContentfulCategory,
  ContentfulHero: ContentfulHero,
  ContentfulAccordion: ContentfulAccordion,
  ContentfulRichText: ContentfulRichText,
  ContentfulGallery: ContentfulGallery,
  ContentfulBlogContent: ContentfulBlogContent,
  ContentfulTileSection: ContentfulTileSection,
  ContentfulTile: ContentfulTile,
  ContentfulNavigationItem: ContentfulNavigationItem,
  ContentfulPage: ContentfulPage,
  ContentfulGlobalSizeGuide: ContentfulGlobalSizeGuide,
  ContentulSlider: ContentfulSlider,
  ContentfulTabs: ContentfulTabs,
  ContentfulButton: ContentfulButton,
  ContentfulImageLink: ContentfulImageLink,
  ContentfulAssetWrapper: ContentfulAssetWrapper,
  ContentfulCareModule: ContentfulCareModule,
  ContentfulCarousel: ContentfulCarousel,
}

export const MediaType = {
  JPG: "image/jpeg",
  PNG: "image/png",
  WEBP: "image/webp",
  GIF: "image/gif",
  MP4: "video/mp4",
  TEXT: "text/html",
  QUICKTIME: "video/quicktime",
}
