import React from "react"
import Image from "../../components/new-ui/image"
import Video from "../../components/new-ui/video/video"
import { MediaType } from "../types"

export const renderMobileOrDesktop = ({
  mobile,
  desktop,
  size,
  mobileThreshold,
}) => {
  if (!mobile || !desktop || !size) return
  if (mobileThreshold && size.width < mobileThreshold) {
    return mobile
  }
  if (isMobileWindow(size.width)) {
    return mobile
  }
  if (isDesktopWindow(size.width)) {
    return desktop
  }
}

// 768 is the px width in theme where tablet size begins
export const isMobileWindow = (size) => {
  return size < 768
}

export const isDesktopWindow = (size) => {
  if (!size) return false
  return size >= 768
}

const imageTypes = [MediaType.JPG, MediaType.PNG, MediaType.WEBP]
const videoTypes = [MediaType.MP4, MediaType.QUICKTIME]

export const handleMediaRender = (asset, rest) => {
  const type = getMediaType(asset)

  if (videoTypes.some((videoType) => videoType === type)) {
    return <Video key={asset.id} src={asset.file.url} {...rest} />
  } else if (imageTypes.some((imageType) => imageType === type)) {
    return (
      <Image key={asset.id} image={asset.gatsbyImageData} alt="" {...rest} />
    )
  }
}

export const getMediaType = (asset) => asset.file.contentType

export const isVideo = (type) =>
  videoTypes.some((videoType) => videoType === type)
