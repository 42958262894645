/* eslint-disable react-hooks/exhaustive-deps */
// Ignore deps for now, find solution later

import React, { useReducer, useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import qs from "query-string"
import { useTranslation } from "react-i18next"

import Medusa from "../services/api"

const DEFAULT_SHIPPING_PROFILE =
  process.env.GATSBY_DEFAULT_SHIPPING_PROFILE || "sp_01EWDSXNDRGJBC8SX9JB7EDYS2"

export const defaultStoreContext = {
  adding: false,
  cart: { items: [], currencyCode: "DKK", region: undefined },
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
}

const StoreContext = React.createContext(defaultStoreContext)
export default StoreContext

export const useStoreContext = () => React.useContext(StoreContext)

const setCart = (cart) => {
  if (cart.customer_id) {
    if (window.analytics) {
      window.analytics.identify(cart.customer_id)
    }
  }

  return {
    id: cart.id,
    loading: false,
    taxRate: parseFloat(cart.region.tax_rate),
    countryCode: cart.shipping_address && cart.shipping_address.country_code,
    currencyCode: cart.region.currency_code,
    items: cart.items,
    total: cart.total,
    discounts: cart.discounts,
    taxTotal: cart.tax_total || 0,
    subtotal: cart.subtotal || 0,
    shippingTotal: cart.shipping_total || 0,
    region: cart.region,
    discountTotal: cart.discount_total || 0,
    freeShippingLimit: cart.freeShippingLimit,
    metadata: cart.metadata,
    context: cart.context,
    ...cart,
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "setCart":
      if (!action.payload) {
        localStorage.removeItem("medusa::cache")
        return {
          ...state,
          cart: { items: [], currencyCode: "DKK" },
        }
      }

      if (localStorage) {
        localStorage.setItem("medusa::cache", JSON.stringify(action.payload))
      }
      return {
        ...state,
        cart: setCart(action.payload),
      }
    case "setFreeShippingLimit":
      // Set or update cache
      localStorage.setItem(
        `pg:fs:${action.payload.regionId}`,
        JSON.stringify(action.payload)
      )
      return {
        ...state,
        freeShippingLimit: action.payload.freeShippingLimit,
      }

    default:
      return state
  }
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultStoreContext)
  const location = useLocation()
  const [prevLocation, setPrevLocation] = useState("")
  const { i18n } = useTranslation()

  const refreshCart = async (cartId) => {
    if (cartId) {
      return Medusa.cart
        .retrieve(cartId)
        .then(({ data }) => {
          dispatch({ type: "setCart", payload: data.cart })
        })
        .catch(() => {
          return Medusa.cart.create().then(({ data }) => {
            dispatch({ type: "setCart", payload: data.cart })
          })
        })
    } else {
      if (state.cart.id) {
        return Medusa.cart
          .retrieve(state.cart.id)
          .then(({ data }) => {
            dispatch({ type: "setCart", payload: data.cart })
          })
          .catch(() => {
            return Medusa.cart.create().then(({ data }) => {
              dispatch({ type: "setCart", payload: data.cart })
            })
          })
      }
    }
  }

  const createCart = async () => {
    let gaId
    if (localStorage) {
      gaId = localStorage.getItem("teklascd")
    }

    // Check shipping options cache and/or create it
    return Medusa.cart
      .create({
        context: {
          locale: i18n.language,
          ga_id: gaId,
        },
      })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        getFreeShippingLimit(data.cart.region_id)
      })
  }

  useEffect(() => {
    if (
      prevLocation.includes("checkout") &&
      !location.pathname.includes("checkout")
    ) {
      refreshCart()
    }

    setPrevLocation(location.pathname)
  }, [location])

  useEffect(() => {
    const isPGCleared = localStorage.getItem("pg_clear")
    if (!isPGCleared) {
      localStorage.setItem("pg_clear", "t")
      createCart()
    } else {
      const temp = qs.parse(window.location.search)
      if (temp.ct) {
        refreshCart(temp.ct)
      } else {
        if (localStorage) {
          const cachedCart = localStorage.getItem("medusa::cache")
          if (cachedCart) {
            const cart = JSON.parse(cachedCart)
            if (cart.completed_at) {
              createCart()
            } else {
              dispatch({ type: "setCart", payload: cart })
              getFreeShippingLimit(cart.region_id)
              refreshCart(cart.id)
            }
          } else {
            createCart()
          }
        }
      }
    }
  }, [])

  const addVariantToCart = async ({ variantId, quantity, metadata }) => {
    return Medusa.cart.lineItems
      .create(state.cart.id, {
        variant_id: variantId,
        quantity,
        metadata,
      })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const removeLineItem = (lineId) => {
    return Medusa.cart.lineItems
      .delete(state.cart.id, lineId)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const updateLineItem = (lineId, quantity) => {
    return Medusa.cart.lineItems
      .update(state.cart.id, lineId, { quantity })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  useEffect(() => {
    if (state.cart && state.cart.id) {
      if (state.cart.context && state.cart.context.locale === i18n.language) {
        return
      }

      Medusa.cart
        .update(state.cart.id, {
          context: {
            locale: i18n.language,
          },
        })
        .then(({ data }) => {
          dispatch({ type: "setCart", payload: data.cart })
          return data
        })
    }
  }, [i18n.language])

  useEffect(() => {
    let gaId
    if (localStorage) {
      gaId = localStorage.getItem("teklascd")
    }

    if (state.cart?.context && gaId) {
      if (state.cart.context.ga_id !== gaId) {
        Medusa.cart
          .update(state.cart.id, {
            context: {
              locale: i18n.language,
              ga_id: gaId,
            },
          })
          .then(({ data }) => {
            dispatch({ type: "setCart", payload: data.cart })
            return data
          })
      }
    }
  }, [state.cart.id])

  const setRegion = (regionId, countryCode) => {
    return Medusa.cart
      .update(state.cart.id, {
        region_id: regionId,
        country_code: countryCode,
      })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        getFreeShippingLimit(data.cart.region_id)
        return data
      })
  }

  const getFreeShippingLimit = async (regionId) => {
    // Get cache
    const data = localStorage.getItem(`pg:fs:${regionId}`)

    let result

    const now = new Date()
    const nowUnix = parseInt(now.getTime().toFixed(0))

    if (data) {
      const regionCache = JSON.parse(data)

      if (regionCache.expiryDate > nowUnix) {
        result = regionCache
      }
    }
    if (!result) {
      result = await Medusa.shippingOptions
        .list({ region_id: regionId })
        .then(({ data }) => {
          const freeShippingLimit = data.shipping_options.reduce(
            (acc, nextValue) => {
              if (nextValue.profile_id !== DEFAULT_SHIPPING_PROFILE) {
                return acc
              }

              // If we find a free shipping limit, find the lowest requirement
              if (nextValue.amount === 0) {
                const limit = nextValue.requirements.find(
                  (r) => r.type === "min_subtotal"
                )

                if (typeof limit === "undefined") {
                  acc = 0
                } else {
                  if (typeof acc === "undefined" || acc > limit.amount) {
                    acc = limit.amount
                  }
                }
              }
              return acc
            },
            undefined
          )

          // Add 24 hours from today as expiry date
          let expiryDate = parseInt(now.getTime()) + 24 * 60 * 60 * 1000

          return {
            regionId: regionId,
            freeShippingLimit: freeShippingLimit,
            expiryDate: expiryDate,
          }
        })
    }

    dispatch({ type: "setFreeShippingLimit", payload: result })
  }

  const removeCouponCode = (couponCode) => {
    return Medusa.cart.discounts
      .delete(state.cart.id, couponCode)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const addCouponCode = (couponCode) => {
    return Medusa.cart
      .update(state.cart.id, {
        discounts: [{ code: couponCode }],
      })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const addGiftCardCode = (giftcardCode) => {
    return Medusa.cart
      .update(state.cart.id, {
        gift_cards: [{ code: giftcardCode }],
      })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const addShippingMethod = (payload) => {
    return Medusa.cart
      .setShippingMethod(state.cart.id, payload)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const update = (payload) => {
    return Medusa.cart.update(state.cart.id, payload).then(({ data }) => {
      dispatch({ type: "setCart", payload: data.cart })
      return data
    })
  }

  const clearCart = () => {
    createCart()
  }

  return (
    <StoreContext.Provider
      value={{
        ...state,
        update,
        addShippingMethod,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        addCouponCode,
        removeCouponCode,
        addGiftCardCode,
        setRegion,
        clearCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
