import React from "react"

import { ThemeProvider } from "./src/Theme/index"
import NotificationProvider from "./src/components/Notifications/NotificationProvider"
import CookieConsent from "./src/components/ui/CookieConsent"
import AccountProvider from "./src/context/AccountContext"
import { InterfaceProvider } from "./src/context/InterfaceContext"
import { PersonalizationProvider } from "./src/context/personalization-context"
import "./src/main.css"

import "./i18next.js"

export const onClientEntry = () => {
  if (typeof window.Intl === "undefined") {
    window.Intl = require("intl")
  }

  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }
}
export const wrapRootElement = ({ element }) => {
  return (
    <InterfaceProvider>
      <PersonalizationProvider>
        <NotificationProvider>
          <AccountProvider>{element}</AccountProvider>
          <CookieConsent />
        </NotificationProvider>
      </PersonalizationProvider>
    </InterfaceProvider>
  )
}

export { wrapPageElement } from "./gatsby-ssr"
