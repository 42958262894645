import React from "react"
import styled from "@emotion/styled"
import { Flex, Box } from "theme-ui"

import { handleMediaRender } from "../../../utils/render-util"
import { navigate } from "gatsby-link"
import handleReferenceLink from "../../../utils/functions/handleReferenceLink"
import { useTranslationContext } from "../../../context/TranslationContext"

const AssetContainer = styled(Flex)`
  flex-direction: ${(props) => props.sx?.flexDirection ?? "row"};
  flex: ${(props) => props?.sx?.flex ?? "1"};
  cursor: ${(props) => props.withReference && "pointer"};

  .previous {
    transform: rotate(180deg);
  }
`

const ImageText = styled(Box)`
  ${(props) =>
    props.placementOnImage &&
    `
      position: absolute;
      bottom: 0;
    `}
`

const AssetWrapper = ({ asset, className, id, index, ...rest }) => {
  const { imageText, hasDarkBackground, textPlacement, reference } = rest
  const { translationContext } = useTranslationContext()

  const handleAssetWrapperClicked = () => {
    navigate(
      handleReferenceLink({
        reference: reference,
        locale: translationContext.locale,
      })
    )
  }

  return (
    <AssetContainer
      className={className}
      id={id}
      index={index}
      withReference={reference}
      onClick={handleAssetWrapperClicked}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        {handleMediaRender(asset, rest)}
        {imageText && (
          <ImageText
            placementOnImage={textPlacement === PlacementOptions.ON_IMAGE}
            sx={{
              p: "8px",
              color: hasDarkBackground ? "white" : "dark",
            }}
          >
            {imageText}
          </ImageText>
        )}
      </Box>
    </AssetContainer>
  )
}

const PlacementOptions = {
  ON_IMAGE: "On Image",
  UNDER: "Under",
}

export default AssetWrapper
