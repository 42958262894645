import React from "react"
import { ThemeProvider as Provider } from "theme-ui"
import mq from "../utils/mq"
import { buttons } from "./buttons"
import { colors } from "./colors"

const Theme = {
  maxWidth: "1280px",
  sections: {
    fullHd: "830px",
    full: "720px",
    fullMobile: "560px",
    large: "680px",
    medium: "540px",
    small: "420px",
  },
  breakpointsLegacy: {
    smallMobile: "@media only screen and (min-width: 375px)",
    mobile: "@media only screen and (min-width: 576px)",
    tablet: "@media only screen and (min-width: 768px)",
    desktop: "@media only screen and (min-width: 992px)",
    hd: "@media only screen and (min-width: 1200px)",
    fullHd: "@media only screen and (min-width: 1920px)",
  },

  breakpoints: [`768px`, "992px", "1440px"],

  padding: {
    y: mq({
      paddingTop: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
      paddingBottom: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
    }),
    x: mq({
      paddingLeft: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
      paddingRight: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
    }),
  },
  margin: {
    y: mq({
      marginTop: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
      marginBottom: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
    }),
    x: mq({
      marginLeft: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
      marginRight: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
    }),
  },
  responsive: {
    padding: {
      y: mq({
        paddingTop: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
        paddingBottom: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
      }),
      x: mq({
        paddingLeft: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
        paddingRight: [`1.5rem`, `1.5rem`, `3rem`, `3rem`],
      }),
    },
  },
  typography: {
    minor: ".8rem",
    body: "1rem",
    header: "1.25rem",
    major: "2rem",
  },
  colors,
  buttons,
  borders: {
    muted: "1px solid #4545451A",
    blackTransparent: `1px solid #0000001a`,
    black: "1px solid black",
    grey: "1px solid #E9E9E9",
    dark: "1px solid #454545",
    brownGrey: "1px solid rgba(159, 159, 159, 0.4)",
    danger: "1px solid #EB0000",
  },
  fontSize: {
    xxs: "10px",
    xs: "12px",
    s: "14px",
    b: "16px",
    m: "18px",
    l: "24px",
    xl: "34px",
  },
  shadows: {
    descrete: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    normal:
      "1px 1px 10px 3px rgb(0 0 0 /20%), -1px -1px 10px 3px rgb(0 0 0 / 20%)",
    heavy:
      "-1px -1px 16px 1px rgba(0,0,0,0.35),1px 1px 16px 1px rgba(0,0,0,0.35)",
    dropShadowNormal: "drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.08))",
  },

  navbar: {
    height: "46px",
    promotional: "76px",
    promoHeight: "30px",
    logoHeight: "18px",
  },
}

export default Theme

export const ThemeProvider = ({ children }) => (
  <Provider theme={Theme}>{children}</Provider>
)
