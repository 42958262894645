import styled from "@emotion/styled"
import { Link } from "gatsby"
import React, { useState } from "react"
import { Flex, Text } from "theme-ui"
import Arrow from "../../../assets/svg/signifly-arrow.svg"
import { ContentfulTypes } from "../../../utils/types"
import RichText from "../rich-text"

const Item = styled(Flex)`
  .content {
    display: none;
  }
  a {
    text-decoration: none;
  }

  .icon {
    margin-right: 10px;
    transform: rotate(90deg);

    &.right {
      transform: rotate(0deg);
      margin-right: 10px;
    }
  }

  .name {
    cursor: pointer;
  }

  ${(props) =>
    props.open &&
    `
    .content {
      display: flex;
      margin: 4px 0;
    }

    .name {
      font-weight: 400;
    }

    .icon {
      transform: rotate(270deg);
    }
  `}
`

const Accordion = ({ items, sx, itemSx }) => {
  const [open, setOpen] = useState({})

  const handleContentRender = (item) => {
    if (item?.internal?.type === ContentfulTypes.ContentfulRichText)
      return <RichText input={item} />

    if (item.contentRender) return item.contentRender()

    return item.content
  }

  return (
    <Flex
      sx={{ color: "dark", flexDirection: "column", ...sx }}
      className="accordion"
    >
      {items &&
        items.map((item) => {
          if (
            item.internal?.type === ContentfulTypes.ContentfulNavigationItem
          ) {
            return (
              <Item
                sx={{
                  flexDirection: "column",
                  fontSize: itemSx?.fontSize || "13px",
                  lineHeight: itemSx?.lineHeight || "17px",
                  ...itemSx,
                }}
              >
                <Link to={item.linkTo}>
                  <Flex
                    sx={{ alignItems: "center" }}
                    className="accordion-header"
                  >
                    <Arrow className="icon right" />
                    <Text className="name">{item.name || item.title}</Text>
                  </Flex>
                </Link>
              </Item>
            )
          }
          return (
            <Item
              sx={{
                flexDirection: "column",
                fontSize: itemSx?.fontSize || "13px",
                lineHeight: itemSx?.lineHeight || "17px",
                ...itemSx,
              }}
              my={itemSx?.marginY || "4px"}
              key={item.id}
              open={item.id === open?.id}
              onClick={() => {
                if (item.id === open?.id) {
                  setOpen()
                } else {
                  setOpen(item)
                }
              }}
            >
              <Flex sx={{ alignItems: "center" }} className="accordion-header">
                <Arrow className="icon" />
                <Text className="name">{item.name || item.title}</Text>
              </Flex>

              <Flex sx={{ flexDirection: "column" }} className="content">
                {handleContentRender(item)}
              </Flex>
            </Item>
          )
        })}
    </Flex>
  )
}

export default Accordion
